import React, { useState, useEffect, useCallback } from 'react';
import { Button } from './components/ui/button';
import { Card, CardHeader, CardContent } from './components/ui/card';
import { Alert, AlertDescription } from './components/ui/alert';
import { Input } from './components/ui/input';
import { PlayCircle, StopCircle } from 'lucide-react';

const Dashboard = () => {
  const [serverStatus, setServerStatus] = useState('offline');
  const [updateProgress, setUpdateProgress] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const fetchServerStatus = useCallback(async () => {
    try {
      const response = await fetch('/api/server-status', {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      if (response.ok) {
        const data = await response.json();
        setServerStatus(data.status);
      } else if (response.status === 401) {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('Failed to fetch server status:', error);
    }
  }, []);

  const fetchUpdateProgress = useCallback(async () => {
    if (!isUpdating) return;
    try {
      const response = await fetch('/api/update-progress', {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      if (response.ok) {
        const data = await response.json();
        setUpdateProgress(data.progress);
        if (data.progress === 100) {
          setIsUpdating(false);
        }
      } else if (response.status === 401) {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('Failed to fetch update progress:', error);
    }
  }, [isUpdating]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      fetchServerStatus();
      const intervalId = setInterval(() => {
        fetchServerStatus();
        fetchUpdateProgress();
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn, fetchServerStatus, fetchUpdateProgress]);

  const login = async () => {
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        setIsLoggedIn(true);
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  //   const login = async () => {
  //   // Hardcoded credentials for demonstration
  //   const DEMO_USERNAME = 'admin';
  //   const DEMO_PASSWORD = 'admin';

  //   if (username === DEMO_USERNAME && password === DEMO_PASSWORD) {
  //     // In a real app, you'd receive a token from the server
  //     const fakeToken = btoa(username + ':' + new Date().getTime());
  //     localStorage.setItem('token', fakeToken);
  //     setIsLoggedIn(true);
  //   } else {
  //     alert('Invalid credentials. Please use admin/password123');
  //   }
  // };

  const logout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  const triggerUpdate = async () => {
    try {
      const response = await fetch('/api/trigger-update', {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      if (response.ok) {
        setIsUpdating(true);
        setUpdateProgress(0);
      } else if (response.status === 401) {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('Failed to trigger update:', error);
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <Card className="w-96">
          <CardHeader>Login</CardHeader>
          <CardContent>
            <Input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="mb-4"
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mb-4"
            />
            <Button onClick={login} className="w-full">
              Login
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Tile Management Dashboard</h1>
        <Button onClick={logout}>Logout</Button>
      </div>
      <Card className="mb-4">
        <CardHeader>Server Status</CardHeader>
        <CardContent>
          <Alert variant={serverStatus === 'online' ? 'default' : 'destructive'}>
            <AlertDescription>
              {serverStatus === 'online' ? (
                <PlayCircle className="inline mr-2" />
              ) : (
                <StopCircle className="inline mr-2" />
              )}
              Server is {serverStatus}
            </AlertDescription>
          </Alert>
        </CardContent>
      </Card>
      <Card className="mb-4">
        <CardHeader>Update Control</CardHeader>
        <CardContent>
          <Button onClick={triggerUpdate} disabled={isUpdating || serverStatus === 'offline'}>
            {isUpdating ? 'Updating...' : 'Trigger Update'}
          </Button>
        </CardContent>
      </Card>
      {isUpdating && (
        <Card>
          <CardHeader>Update Progress</CardHeader>
          <CardContent>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${updateProgress}%` }}
              ></div>
            </div>
            <p className="mt-2">{updateProgress}% Complete</p>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default Dashboard;